<template>
  <div class="plan mb-3">
    <div class="image-wrapper subscription">
      <img
        v-for="market in manual_subscription.markets"
        v-bind:key="market.id"
        :src="require(`@/assets/icon-${market.slug}-big.svg`)"
        :title="market.name"
        :alt="market.name"
      />
    </div>

    <h5>{{ manual_subscription.markets.map(ms => ms.name).join(', ') }}</h5>

    <div class="validation">
      <i class="fas fa-check bought"></i>
      <p>
        {{ $t('profile.payment_and_subscription.valid_until') }} {{ manual_subscription.unlimited_access ? $t('profile.payment_and_subscription.unlimited') : manual_subscription.valid_until }}
      </p>

      <base-button
        @click.native="cancel_reactivate_subscription_popup(manual_subscription.id, subscription_type = 'manual')"
        id="cancel-subscription"
        :disabled="current_user.subscriptions.find(s => s.id === manual_subscription.id).canceled"
      >
        {{ current_user.subscriptions.find(s => s.id === manual_subscription.id).canceled ? $t('profile.payment_and_subscription.canceled') : $t('profile.payment_and_subscription.cancel_subscription') }}
      </base-button>

      <br><br>

      <base-button
        @click.native="cancel_reactivate_subscription_popup(manual_subscription.id, subscription_type = 'manual', action_type = 'reactivate')"
        v-if="current_user.subscriptions.find(s => s.id === manual_subscription.id).canceled"
      >
        {{ $t('profile.payment_and_subscription.reactivate_subscription') }}
      </base-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BaseButton from './BaseButton.vue'

export default {
  data () {
    return {
      env: {
        environment: process.env.NODE_ENV,
        api_url: process.env.VUE_APP_API_URL
      }
    }
  },
  components: {
    BaseButton
  },
  props: {
    manual_subscription: null
  },
  computed: {
    ...mapState({
      current_user: state => state.profile.account.current_user
    })
  },
  methods: {
    cancel_reactivate_subscription_popup (subscription_id, subscription_type = 'recurly', action_type = 'cancel') {
      this.$parent.cancel_reactivate_subscription_popup(subscription_id, subscription_type, action_type)
    }
  }
}
</script>

<style scoped lang="scss">
.bought {
  font-size: 33px;
  color: var(--tealish);
}
</style>
